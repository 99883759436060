import { useState } from "react";
import * as api from "@screencloud/billing-client-api";

import { getClient } from "src/billinglatest/clients/service.client";
import { useAppContext } from "src/hooks/useAppContext";

import { Customer, HookProps } from "src/billinglatest/types";

/**
 * This mapper function currently does nothing, as the api type is the same as the UI type.
 * However, it's here to allow for future changes to the API without affecting the UI, severing the
 * dependency between the two. If we decide in future to make a change to the API, we can do so without
 * affecting the UI, as long as we update this mapper function to map the new API type to the existing UI type.
 *
 * @param customer - Customer object from the API
 */
export const mapFromApi = (customer: api.Customers.Customer): Customer => {
  return customer;
};

export interface UseCustomer {
  hasBillingAddress: () => boolean;
  isChargebee: () => boolean;
  providerName: () => string;
  providerLink: () => string;
  set: (input: Customer) => void;
  get: () => Customer;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
}

export function useCustomer(props?: HookProps): UseCustomer {
  const context = useAppContext();
  const [_get, _set] = useState({} as Customer);

  /**
   * Determines if the customer has a billing address on record.
   *
   * @remarks
   * This function checks if the customer has provided their billing address. Having a valid billing address is crucial
   * for further processing, and certain actions should be restricted until this information is available. The presence
   * of a billing address is determined primarily by the country field.
   */
  const hasBillingAddress = (): boolean => {
    const billingAddress = get().billingAddress;
    return !!(billingAddress && billingAddress.country);
  };

  /**
   * Checks if the customer is using Chargebee as their provider.
   *
   * @remarks
   * Only those on Chargebee get the full billing section. The rest, well, they're considered partners and get a
   * severely limited view, if any.
   */
  const isChargebee = (): boolean => {
    return get().provider === "chargebee";
  };

  /**
   * Fetch the user-friendly name of the provider.
   *
   * @remarks
   * While typically reserved for non-Chargebee customers, we default to Chargebee if there's no specific match.
   */
  const providerName = (): string => {
    switch (get().provider) {
      case "azure":
        return "Microsoft Azure Marketplace";
      default:
        return "Chargebee";
    }
  };

  /**
   * Retrieve the link to manage subscriptions for the provider.
   *
   * @remarks
   * Chargebee will give you an empty string since the management hub is right here in Studio. We're just making sure
   * it's falsy to dodge those undefined errors.
   */
  const providerLink = (): string => {
    switch (get().provider) {
      case "azure":
        return "https://portal.azure.com/#view/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources";
      default:
        return "";
    }
  };

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the customer.
   */
  const set = (input: Customer): void => {
    return _set(input || {});
  };

  /**
   * Return the customer.
   */
  const get = (): Customer => {
    return _get;
  };

  /**
   * Fetches the customer from the billing service.
   *
   * @remarks
   * This function retrieves the customer from the billing service and stores it within the hook.
   */
  const fetch = async (): Promise<void> => {
    const customer = await getClient()?.customer.getCustomer(context.currentSpace?.id);
    set(mapFromApi(customer || ({} as api.Customers.Customer)));
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch(), with the added benefit of ensuring the invalidation of any cached data.
   */
  const refetch = async (): Promise<void> => {
    return fetch();
  };

  /**
   * Return the hook
   */
  return {
    hasBillingAddress,
    isChargebee,
    providerName,
    providerLink,
    set,
    get,
    fetch,
    refetch,
  };
}
