import { useState } from "react";
import * as api from "@screencloud/billing-client-api";

import { getClient } from "src/billinglatest/clients/service.client";

import { util } from "src/billinglatest/util";
import { useAppContext } from "src/hooks/useAppContext";

import { HookProps, Plan } from "src/billinglatest/types";

/**
 * This mapper function currently does nothing, as the api type is the same as the UI type.
 * However, it's here to allow for future changes to the API without affecting the UI, severing the
 * dependency between the two. If we decide in future to make a change to the API, we can do so without
 * affecting the UI, as long as we update this mapper function to map the new API type to the existing UI type.
 *
 * @param plan - Plan object from the API
 */
export const mapFromApi = (plan: api.Plans.Plan): Plan => {
  return {
    ...plan,
    version: plan.version || "",
    price: plan.price || 0,
    currency: plan.currency || "",
    licenseCount: plan.licenseCount || 0,
    pricingModel: plan.pricingModel || "flat_fee",
    group: plan.group || "",
  };
};

export interface UsePlan {
  name: () => string;
  term: () => string;
  price: (price?: number) => string;
  periodUnit: () => string;
  priceTotal: () => string;
  currencyCode: () => string;
  includedLicenses: () => number;
  isNoScreensPlan: () => boolean;
  isLegacyPlan: () => boolean;
  isInvoiced: () => boolean;
  set: (input: Plan) => void;
  get: () => Plan;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
}

export function usePlan(props?: HookProps): UsePlan {
  const context = useAppContext();
  const [_get, _set] = useState({} as Plan);

  /**
   * Retrieves the human-readable name of the plan.
   *
   * @remarks
   * This function handles legacy plan names, such as "Signage", in addition to the default plan names.
   */
  const name = (): string => {
    if (get().name) {
      return (
        util.currency
          .stripCode(get().name)
          .replace(/annually|annual|monthly/gi, "")
          .trim() + " Plan"
      );
    }
    return "";
  };

  /**
   * Retrieves a human-readable label for the plan term/frequency.
   *
   * @see [termLabel]{@link src/billinglatest/util/plan.tsx}
   */
  const term = (): string => {
    return util.plan.termLabel(get().termNumber, get().term);
  };

  /**
   * Retrieves a human-readable label for the plan frequency.
   *
   * @see [termLabel]{@link src/billinglatest/util/plan.tsx}
   */
  const periodUnit = (): string => {
    return util.plan.periodUnit(get().termNumber, get().term);
  };

  /**
   * Price per license
   */
  const price = (price?: number): string => {
    return util.currency.formatted(currencyCode(), price || get().price || 0);
  };

  /**
   * Calculates the total price of the plan based on the included licenses.
   *
   * @remarks
   * The total price is determined by multiplying the number of included licenses by the price. This represents the
   * sign-up cost for this plan.
   */
  const priceTotal = (): string => {
    return util.currency.formatted(currencyCode(), includedLicenses() * (get().price || 0));
  };

  /**
   * Retrieves the uppercase version of the currency code.
   *
   * @remarks
   * This function returns the currency code in uppercase. Alternatively, you can obtain this value from the
   * subscription hook.
   *
   * @see [useSubscription]{@link src/billinglatest/hooks/useSubscription.ts} for accessing the subscription hook.
   */
  const currencyCode = (): string => {
    return get().currency.toUpperCase();
  };

  /**
   * Retrieves the number of included physical licenses.
   *
   * @remarks
   * For legacy plans that do not use this feature, the value will be falsy, and we return 0 in such cases. Make sure
   * your code handles this condition appropriately.
   */
  const includedLicenses = (): number => {
    return get().licenseCount || 0;
  };

  /**
   * Checks if this plan is a no-screens plan.
   *
   * @legacy
   * @remarks
   * Legacy plans had the concept of a no-screens plan, which allowed the subscription to remain active even with 0
   * licenses/screens. This feature was discontinued in v3+ of the plans.
   */
  const isNoScreensPlan = (): boolean => {
    return !!(get().subscriptionType === "no-screens");
  };

  /**
   * Determines if the plan is considered legacy.
   *
   * @remarks
   * At the time of writing this documentation, any plan version below v3 is considered legacy. Legacy plans typically
   * include the no-screens feature.
   */
  const isLegacyPlan = (): boolean => {
    return ["v1", "v2", ""].includes(get().version || "");
  };

  /**
   * Checks if this plan allows invoiced payment methods.
   *
   * @legacy
   * @remarks
   * Legacy plans had the concept of being invoiced attached to them. However, in current plans (v3+), the invoiced
   * payment method indicator is attached to the customer instead.
   */
  const isInvoiced = (): boolean => {
    const type = get().subscriptionType;
    return type === "annually-invoiced";
  };

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the plan.
   */
  const set = (input: Plan): void => {
    return _set(input || {});
  };

  /**
   * Return the plan.
   */
  const get = (): Plan => {
    return _get;
  };

  /**
   * Fetches the plan from the billing service.
   *
   * @remarks
   * This function retrieves the plan from the billing service and stores it within the hook.
   */
  const fetch = async (): Promise<void> => {
    const response = await getClient()?.plans.getPlan(context.currentSpace?.id);
    const plan = mapFromApi(response || ({} as api.Plans.Plan));
    set(plan);
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch(), with the added benefit of ensuring the invalidation of any cached data.
   */
  const refetch = async (): Promise<void> => {
    return fetch();
  };

  /**
   * Return the hook
   */
  return {
    name,
    term,
    periodUnit,
    price,
    priceTotal,
    currencyCode,
    includedLicenses,
    isNoScreensPlan,
    isLegacyPlan,
    isInvoiced,
    set,
    get,
    fetch,
    refetch,
  };
}
